@import "~antd/dist/antd.css";

@font-face {
  font-family: Sommet;
  src: url("./fonts/Sommet.otf") format("opentype");
}

.landing-page {
  color: floralwhite;
  font-family: "Sommet";
}

.footer {
  background: #001529;
  color: floralwhite;
  flex-wrap: wrap;
}
.footer a {
  color: floralwhite;
}
.footer ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.footer ul li {
  padding: 0 20px;
  color: rgba(238, 238, 238, 0.7);
}
.footer ul li:first-child {
  padding-left: 0;
}

.footer ul li:last-child {
  flex: 1;
  text-align: right;
  font-size: 10px;
  font-weight: 300;
}

/* Hide table placeholder text while loading */
.ant-spin-container.ant-spin-blur .ant-table-placeholder {
  color: transparent;
}

/* Resolves issue with vertical scroll when screen takes up >100vh */
.ant-layout-content {
  min-height: auto;
}
h2 {
  font-family: "Sommet";
  margin: 0;
}

.course-card {
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 0 12px 12px 0;
  max-width: 200px;
}
.course-card:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.25), 0 1px 6px rgba(0,0,0,0.22);
}
.ant-drawer-content-wrapper {
  width: 100% !important;
  max-width: 550px !important;
}
.detail-view .ant-collapse-item {
  border: 0;
}

@media print {
  .print {
    display: block !important;
  }
}