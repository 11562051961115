body {
  margin: 0;
  padding: 0;
  background: transparent;
}

body,
#root {
  height: 100%;
}
